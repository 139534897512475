import { PATCH } from "@/core/services/store/request.module";

export default {
  methods: {
    patchSupplier(supplier, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (supplier) {
          _this.$store
            .dispatch(PATCH, { url: "supplier/" + supplier, data })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              resolve(error);
            });
        } else {
          reject("Supplier is required");
        }
      });
    },
  },
};
