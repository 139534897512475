var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-col',{staticClass:"custom-content-container pt-0",attrs:{"cols":"12","md":_vm.isDialog ? 12 : 9,"sm":_vm.isDialog ? 12 : 9}},[_c('v-layout',{staticClass:"d-block"},[_c('v-row',[_c('v-col',{staticClass:"iv-custom-field",attrs:{"cols":"3","md":"3","sm":"3"},on:{"mouseleave":function($event){_vm.imageTemplate = false},"mouseover":function($event){_vm.imageTemplate = true}}},[[_c('v-img',{staticClass:"custom-grey-border custom-border-radius-50 company-image-logo margin-auto",attrs:{"lazy-src":_vm.$defaultProfileImage,"src":_vm.getProfileImage,"max-height":"200","max-width":"200","height":"200px","width":"200px"}}),_c('div',{staticClass:"margin-auto"},[_c('v-btn',{staticClass:"my-4 custom-bold-button width-100",attrs:{"outlined":"","small":"","color":"cyan","loading":_vm.imageLoading,"disabled":_vm.imageLoading},on:{"click":_vm.selectImage}},[(_vm.getProfileImage)?[_vm._v("Change Image")]:[_vm._v("Upload Image")]],2),_c('div',{staticClass:"d-none"},[_c('v-file-input',{ref:"uploadImage",attrs:{"rules":_vm.imageRules,"accept":"image/png, image/jpeg, image/jpg"},on:{"change":_vm.updateProfileImage}})],1)],1)]],2),_c('v-col',{staticClass:"iv-custom-field",attrs:{"cols":"9","md":"9","sm":"9"}},[_c('v-container',{attrs:{"fluid":""}},[(_vm.lodash.isEmpty(_vm.supplier))?_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"md":"3","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.salutationList,"rules":[
                    _vm.validateRules.required(_vm.detail.salutation, 'salutation') ],"dense":"","filled":"","item-text":"text","item-value":"value","item-color":"cyan","label":"Salutation","solo":"","flat":"","color":"cyan"},model:{value:(_vm.detail.salutation),callback:function ($$v) {_vm.$set(_vm.detail, "salutation", $$v)},expression:"detail.salutation"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"md":"5","sm":"12"}},[_c('v-text-field',{staticClass:"required-field",attrs:{"rules":[
                    _vm.validateRules.required(_vm.detail.first_name, 'first name'),
                    _vm.validateRules.minLength(
                      _vm.detail.first_name,
                      'first name',
                      2
                    ),
                    _vm.validateRules.maxLength(
                      _vm.detail.first_name,
                      'first name',
                      100
                    ) ],"dense":"","filled":"","label":"First Name","solo":"","flat":"","color":"cyan"},model:{value:(_vm.detail.first_name),callback:function ($$v) {_vm.$set(_vm.detail, "first_name", $$v)},expression:"detail.first_name"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"rules":[
                    _vm.validateRules.minLength(_vm.detail.last_name, 'last name', 2),
                    _vm.validateRules.maxLength(
                      _vm.detail.last_name,
                      'last name',
                      100
                    ) ],"dense":"","filled":"","label":"Last Name","solo":"","flat":"","color":"cyan"},model:{value:(_vm.detail.last_name),callback:function ($$v) {_vm.$set(_vm.detail, "last_name", $$v)},expression:"detail.last_name"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"md":"12","sm":"12"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"inset":"","color":"cyan","label":"Use company name as the display name"},model:{value:(_vm.detail.primary),callback:function ($$v) {_vm.$set(_vm.detail, "primary", $$v)},expression:"detail.primary"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"rules":[
                    _vm.validateRules.minLength(
                      _vm.detail.company_name,
                      'company name',
                      2
                    ),
                    _vm.validateRules.maxLength(
                      _vm.detail.company_name,
                      'company name',
                      100
                    ) ],"dense":"","filled":"","label":"Company Name","solo":"","flat":"","color":"cyan"},model:{value:(_vm.detail.company_name),callback:function ($$v) {_vm.$set(_vm.detail, "company_name", $$v)},expression:"detail.company_name"}})],1),_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('v-text-field',{staticClass:"required-field",attrs:{"readonly":_vm.detail.primary,"rules":[
                    _vm.validateRules.required(
                      _vm.detail.display_name,
                      'display name'
                    ),
                    _vm.validateRules.minLength(
                      _vm.detail.display_name,
                      'display name',
                      2
                    ),
                    _vm.validateRules.maxLength(
                      _vm.detail.display_name,
                      'display name',
                      100
                    ) ],"dense":"","filled":"","label":"Display Name","solo":"","flat":"","color":"cyan"},model:{value:(_vm.detail.display_name),callback:function ($$v) {_vm.$set(_vm.detail, "display_name", $$v)},expression:"detail.display_name"}})],1),_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.groupList,"dense":"","filled":"","rules":[_vm.validateRules.required(_vm.detail.group_id, 'group')],"item-text":"text","item-value":"id","item-color":"cyan","label":"Group","solo":"","flat":"","color":"cyan"},model:{value:(_vm.detail.group_id),callback:function ($$v) {_vm.$set(_vm.detail, "group_id", $$v)},expression:"detail.group_id"}})],1),(false)?_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.currentPhoneMask),expression:"currentPhoneMask"}],staticClass:"required-field",attrs:{"rules":[
                    _vm.validateRules.required(_vm.detail.phone, 'phone'),
                    _vm.validateRules.minLength(_vm.detail.phone, 'phone', 6),
                    _vm.validateRules.maxLength(_vm.detail.phone, 'phone', 15) ],"dense":"","filled":"","label":"Phone","solo":"","flat":"","color":"cyan"},model:{value:(_vm.detail.phone),callback:function ($$v) {_vm.$set(_vm.detail, "phone", $$v)},expression:"detail.phone"}})],1):_vm._e(),_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('v-text-field',{staticClass:"required-field",attrs:{"rules":[
                    _vm.validateRules.minLength(_vm.detail.website, 'website', 3),
                    _vm.validateRules.maxLength(_vm.detail.website, 'website', 50) ],"dense":"","filled":"","label":"Website","solo":"","flat":"","color":"cyan"},model:{value:(_vm.detail.website),callback:function ($$v) {_vm.$set(_vm.detail, "website", $$v)},expression:"detail.website"}})],1),(false)?_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('v-text-field',{staticClass:"required-field",attrs:{"rules":[
                    _vm.validateRules.required(
                      _vm.detail.security_code,
                      'security code'
                    ),
                    _vm.validateRules.minLength(
                      _vm.detail.security_code,
                      'security code',
                      3
                    ),
                    _vm.validateRules.maxLength(
                      _vm.detail.security_code,
                      'security code',
                      50
                    ) ],"dense":"","filled":"","label":"Security Code","solo":"","flat":"","color":"cyan"},model:{value:(_vm.detail.security_code),callback:function ($$v) {_vm.$set(_vm.detail, "security_code", $$v)},expression:"detail.security_code"}})],1):_vm._e(),(false)?[_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('div',{staticClass:"custom-google-autocomplete"},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],staticClass:"required-field",attrs:{"append-icon":"search","loading":_vm.autocompleteLoading,"rules":[
                          _vm.validateRules.required(
                            _vm.detail.postal_code,
                            'postal code'
                          ),
                          _vm.validateRules.minLength(
                            _vm.detail.postal_code,
                            'postal code',
                            6
                          ),
                          _vm.validateRules.maxLength(
                            _vm.detail.postal_code,
                            'postal code',
                            6
                          ) ],"dense":"","filled":"","label":"Postal Code","solo":"","flat":"","color":"cyan"},on:{"keyup":_vm.getGoogleAddress,"blur":function($event){_vm.isFocused = false},"focus":function($event){_vm.isFocused = true}},model:{value:(_vm.detail.postal_code),callback:function ($$v) {_vm.$set(_vm.detail, "postal_code", $$v)},expression:"detail.postal_code"}}),((hover || _vm.isFocused) && _vm.autocompleteList.length)?_c('v-list',{staticClass:"custom-google-autocomplete-list",attrs:{"two-line":"","elevation":"4"}},_vm._l((_vm.autocompleteList),function(address,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.selectGoogleAddress(index)}}},[_c('v-list-item-icon',{staticClass:"m-0 mr-3 my-auto"},[_c('v-icon',{attrs:{"color":"cyan"}},[_vm._v(" mdi-map-marker ")])],1),(
                              _vm.lodash.isEmpty(
                                address.structured_formatting
                              ) === false
                            )?_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',{staticClass:"font-size-14"},[_vm._v(_vm._s(address.structured_formatting.main_text))]),_c('v-list-item-subtitle',{staticClass:"font-size-12"},[_vm._v(_vm._s(address.structured_formatting.secondary_text))])],1):_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',{staticClass:"font-size-14"},[_vm._v(_vm._s(address.description))])],1)],1)}),1):_vm._e()],1)]}}],null,false,146028547)})],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('v-text-field',{staticClass:"required-field",attrs:{"rules":[
                      _vm.validateRules.minLength(
                        _vm.detail.unit_number,
                        'unit no',
                        1
                      ),
                      _vm.validateRules.maxLength(
                        _vm.detail.unit_number,
                        'unit no',
                        100
                      ) ],"dense":"","filled":"","label":"Unit No.","solo":"","flat":"","color":"cyan"},model:{value:(_vm.detail.unit_number),callback:function ($$v) {_vm.$set(_vm.detail, "unit_number", $$v)},expression:"detail.unit_number"}})],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('v-text-field',{staticClass:"required-field",attrs:{"rules":[
                      _vm.validateRules.minLength(
                        _vm.detail.address_line_1,
                        'address line 1',
                        3
                      ),
                      _vm.validateRules.maxLength(
                        _vm.detail.address_line_1,
                        'address line 1',
                        50
                      ) ],"dense":"","filled":"","label":"Address Line 1","solo":"","flat":"","color":"cyan"},model:{value:(_vm.detail.address_line_1),callback:function ($$v) {_vm.$set(_vm.detail, "address_line_1", $$v)},expression:"detail.address_line_1"}})],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('v-text-field',{staticClass:"required-field",attrs:{"rules":[
                      _vm.validateRules.minLength(
                        _vm.detail.address_line_2,
                        'address line 2',
                        3
                      ),
                      _vm.validateRules.maxLength(
                        _vm.detail.address_line_2,
                        'address line 2',
                        50
                      ) ],"dense":"","filled":"","label":"Address Line 2","solo":"","flat":"","color":"cyan"},model:{value:(_vm.detail.address_line_2),callback:function ($$v) {_vm.$set(_vm.detail, "address_line_2", $$v)},expression:"detail.address_line_2"}})],1)]:_vm._e()],2),[_c('v-row',[_c('v-col',{attrs:{"md":"12","sm":"12"}},[_c('h3',[_vm._v("Contact Details")])])],1),_vm._l((_vm.emailDetails),function(cdata,index){return [_c('v-row',{key:'email-' + index},[_c('v-col',{attrs:{"md":"3","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.emailTypeList,"rules":[_vm.validateRules.required(cdata.title, 'type')],"dense":"","filled":"","item-text":"text","item-value":"value","item-color":"cyan","label":"Type","solo":"","flat":"","color":"cyan"},model:{value:(cdata.title),callback:function ($$v) {_vm.$set(cdata, "title", $$v)},expression:"cdata.title"}})],1),_c('v-col',{attrs:{"md":"7","sm":"12"}},[_c('v-text-field',{staticClass:"required-field",attrs:{"rules":[
                        _vm.validateRules.required(cdata.value, 'email'),
                        _vm.validateRules.validEmail(cdata.value, 'email'),
                        _vm.validateRules.minLength(cdata.value, 'email', 2),
                        _vm.validateRules.maxLength(cdata.value, 'email', 100) ],"dense":"","filled":"","label":"Email","solo":"","flat":"","color":"cyan"},model:{value:(cdata.value),callback:function ($$v) {_vm.$set(cdata, "value", $$v)},expression:"cdata.value"}})],1),_c('v-col',{attrs:{"md":"2","sm":"12"}},[(index > 0)?[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"red lighten-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.removeEmailDetail(index)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-minus")])],1)]:[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"cyan","dark":"","fab":"","small":""},on:{"click":_vm.pushEmailDetail}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus")])],1)]],2)],1)]}),_vm._l((_vm.contactDetails),function(cdata,index){return [_c('v-row',{key:'contact-' + index},[_c('v-col',{attrs:{"md":"3","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.contactTypeList,"rules":[_vm.validateRules.required(cdata.title, 'type')],"dense":"","filled":"","item-text":"text","item-value":"value","item-color":"cyan","label":"Type","solo":"","flat":"","color":"cyan"},model:{value:(cdata.title),callback:function ($$v) {_vm.$set(cdata, "title", $$v)},expression:"cdata.title"}})],1),_c('v-col',{attrs:{"md":"7","sm":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.currentPhoneMask),expression:"currentPhoneMask"}],staticClass:"required-field",attrs:{"rules":[
                        _vm.validateRules.required(cdata.value, 'phone number'),
                        _vm.validateRules.minLength(
                          cdata.value,
                          'phone number',
                          2
                        ),
                        _vm.validateRules.maxLength(
                          cdata.value,
                          'phone number',
                          100
                        ) ],"dense":"","filled":"","label":"Phone Number","solo":"","flat":"","color":"cyan"},model:{value:(cdata.value),callback:function ($$v) {_vm.$set(cdata, "value", $$v)},expression:"cdata.value"}})],1),_c('v-col',{attrs:{"md":"2","sm":"12"}},[(index > 0)?[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"red lighten-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.removeContactDetail(index)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-minus")])],1)]:[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"cyan","dark":"","fab":"","small":""},on:{"click":_vm.pushContactDetail}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus")])],1)]],2)],1)]})]],2)],1)],1)],1)],1),(!_vm.isDialog)?[_c('v-col',{staticClass:"custom-sidebar-container grey lighten-3",attrs:{"cols":"12","md":"3","sm":"3"}},[_vm._l((_vm.fieldDescriptions),function(fieldInfo,indx){return [(fieldInfo.field == _vm.currentActiveField)?_c('div',{key:indx,staticClass:"help-sidebar"},[_c('div',{staticClass:"help-sidebar-header"},[(fieldInfo.title)?_c('h3',{staticClass:"section-header-title"},[_vm._v(" "+_vm._s(fieldInfo.title)+" ")]):_vm._e()]),(fieldInfo.description)?_c('p',{staticClass:"help-sidebar-description"},[_vm._v(" "+_vm._s(fieldInfo.description)+" ")]):_vm._e(),(fieldInfo.footer)?_c('h5',{staticClass:"help-sidebar-footer-title"},[_vm._v(" "+_vm._s(fieldInfo.footer)+" ")]):_vm._e(),(fieldInfo.footer_content)?_c('p',{staticClass:"help-sidebar-footer-text"},[_vm._v(" "+_vm._s(fieldInfo.footer_content)+" ")]):_vm._e()]):_vm._e()]})],2)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }