var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-col',{staticClass:"custom-content-container pt-0",attrs:{"cols":"12","md":_vm.isDialog ? 12 : 9,"sm":_vm.isDialog ? 12 : 9}},[_c('v-layout',{staticClass:"d-block"},[_c('v-row',[_c('v-col',{staticClass:"iv-custom-field pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(false && !_vm.isDialog)?_c('v-col',{staticClass:"pt-0",attrs:{"md":"12","sm":"12"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"inset":"","color":"cyan","label":"Company address is same as Property Address"},model:{value:(_vm.billingAddress.is_same),callback:function ($$v) {_vm.$set(_vm.billingAddress, "is_same", $$v)},expression:"billingAddress.is_same"}})],1):_vm._e(),_c('v-col',{staticClass:"pt-0",attrs:{"md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"6"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"inset":"","label":"Get your current location","color":"cyan"},on:{"change":_vm.getUserCurrentAddress},model:{value:(_vm.trackGPS),callback:function ($$v) {_vm.trackGPS=$$v},expression:"trackGPS"}})],1),_c('v-col',{attrs:{"md":"6","sm":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"custom-google-autocomplete"},[_c('v-text-field',{attrs:{"id":"address-map","append-icon":"search","dense":"","filled":"","label":"Search Address","solo":"","flat":"","color":"cyan","loading":_vm.autocompleteLoading},on:{"keyup":_vm.getGoogleAddress,"blur":function($event){_vm.isFocused = false},"focus":function($event){_vm.isFocused = true}},model:{value:(_vm.searchAddress),callback:function ($$v) {_vm.searchAddress=$$v},expression:"searchAddress"}}),(
                            (hover || _vm.isFocused) && _vm.autocompleteList.length
                          )?_c('v-list',{staticClass:"custom-google-autocomplete-list",attrs:{"two-line":"","elevation":"4"}},_vm._l((_vm.autocompleteList),function(address,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.selectGoogleAddress(index)}}},[_c('v-list-item-icon',{staticClass:"m-0 mr-3 my-auto"},[_c('v-icon',{attrs:{"color":"cyan"}},[_vm._v(" mdi-map-marker ")])],1),(
                                _vm.lodash.isEmpty(
                                  address.structured_formatting
                                ) === false
                              )?_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',{staticClass:"font-size-14"},[_vm._v(_vm._s(address.structured_formatting.main_text))]),_c('v-list-item-subtitle',{staticClass:"font-size-12"},[_vm._v(_vm._s(address.structured_formatting.secondary_text))])],1):_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',{staticClass:"font-size-14"},[_vm._v(_vm._s(address.description))])],1)],1)}),1):_vm._e()],1)]}}])})],1)],1)],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('label',[_vm._v("Unit No.")]),_c('v-text-field',{attrs:{"rules":[
                    _vm.validateRules.maxLength(
                      _vm.billingAddress.unit_no,
                      'Unit No.',
                      100
                    ) ],"dense":"","filled":"","label":"Unit No.","solo":"","flat":"","color":"cyan"},model:{value:(_vm.billingAddress.unit_no),callback:function ($$v) {_vm.$set(_vm.billingAddress, "unit_no", $$v)},expression:"billingAddress.unit_no"}})],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('label',[_vm._v("Address Line 1")]),_c('v-text-field',{attrs:{"rules":[
                    _vm.validateRules.required(
                      _vm.billingAddress.street_1,
                      'Address Line 1'
                    ),
                    _vm.validateRules.maxLength(
                      _vm.billingAddress.street_1,
                      'Address Line 1',
                      255
                    ) ],"dense":"","filled":"","label":"Address Line 1","solo":"","flat":"","color":"cyan"},model:{value:(_vm.billingAddress.street_1),callback:function ($$v) {_vm.$set(_vm.billingAddress, "street_1", $$v)},expression:"billingAddress.street_1"}})],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('label',[_vm._v("Address Line 2")]),_c('v-text-field',{attrs:{"rules":[
                    _vm.validateRules.maxLength(
                      _vm.billingAddress.street_2,
                      'Address Line 2',
                      255
                    ) ],"dense":"","filled":"","label":"Address Line 2","solo":"","flat":"","color":"cyan"},model:{value:(_vm.billingAddress.street_2),callback:function ($$v) {_vm.$set(_vm.billingAddress, "street_2", $$v)},expression:"billingAddress.street_2"}})],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('label',[_vm._v("Postal Code")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('############'),expression:"'############'"}],attrs:{"rules":[
                    _vm.validateRules.required(
                      _vm.billingAddress.zip_code,
                      'Postal Code'
                    ),
                    _vm.validateRules.maxLength(
                      _vm.billingAddress.zip_code,
                      'Postal Code',
                      10
                    ) ],"dense":"","filled":"","label":"Postal Code","solo":"","flat":"","color":"cyan"},model:{value:(_vm.billingAddress.zip_code),callback:function ($$v) {_vm.$set(_vm.billingAddress, "zip_code", $$v)},expression:"billingAddress.zip_code"}})],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('label',[_vm._v("Country")]),_c('v-text-field',{attrs:{"rules":[
                    _vm.validateRules.required(_vm.billingAddress.country, 'Country'),
                    _vm.validateRules.maxLength(
                      _vm.billingAddress.country,
                      'Country',
                      10
                    ) ],"dense":"","filled":"","label":"Country","solo":"","flat":"","color":"cyan"},model:{value:(_vm.billingAddress.country),callback:function ($$v) {_vm.$set(_vm.billingAddress, "country", $$v)},expression:"billingAddress.country"}})],1),_c('v-col',{staticClass:"d-none",attrs:{"md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"rules":[
                    _vm.validateRules.maxLength(
                      _vm.billingAddress.latitude,
                      'Latitude',
                      100
                    ) ],"dense":"","filled":"","label":"Latitude","solo":"","flat":"","color":"cyan"},model:{value:(_vm.billingAddress.latitude),callback:function ($$v) {_vm.$set(_vm.billingAddress, "latitude", $$v)},expression:"billingAddress.latitude"}})],1),_c('v-col',{staticClass:"d-none",attrs:{"md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"rules":[
                    _vm.validateRules.maxLength(
                      _vm.billingAddress.longitude,
                      'Longitude',
                      100
                    ) ],"dense":"","filled":"","label":"Longitude","solo":"","flat":"","color":"cyan"},model:{value:(_vm.billingAddress.longitude),callback:function ($$v) {_vm.$set(_vm.billingAddress, "longitude", $$v)},expression:"billingAddress.longitude"}})],1),_c('v-col',{attrs:{"md":"12","sm":"12"}},[_c('v-row',{staticClass:"py-0"},[_c('v-col',{staticClass:"py-0",attrs:{"md":"6"}},[_c('label',[_vm._v("Remarks")]),_c('v-textarea',{attrs:{"rules":[
                        _vm.validateRules.maxLength(
                          _vm.billingAddress.remarks,
                          'Remarks',
                          512
                        ) ],"auto-grow":"","dense":"","filled":"","label":"Remarks","solo":"","flat":"","row-height":"30","color":"cyan"},model:{value:(_vm.billingAddress.remarks),callback:function ($$v) {_vm.$set(_vm.billingAddress, "remarks", $$v)},expression:"billingAddress.remarks"}})],1)],1)],1)],1)],1)],1)],1)],1)],1),(!_vm.isDialog)?[_c('v-col',{staticClass:"custom-sidebar-container grey lighten-3",attrs:{"cols":"12","md":"3","sm":"3"}},[_vm._l((_vm.fieldDescriptions),function(fieldInfo,indx){return [(fieldInfo.field == _vm.currentActiveField)?_c('div',{key:indx,staticClass:"help-sidebar"},[_c('div',{staticClass:"help-sidebar-header"},[(fieldInfo.title)?_c('h3',{staticClass:"section-header-title"},[_vm._v(" "+_vm._s(fieldInfo.title)+" ")]):_vm._e()]),(fieldInfo.description)?_c('p',{staticClass:"help-sidebar-description"},[_vm._v(" "+_vm._s(fieldInfo.description)+" ")]):_vm._e(),(fieldInfo.footer)?_c('h5',{staticClass:"help-sidebar-footer-title"},[_vm._v(" "+_vm._s(fieldInfo.footer)+" ")]):_vm._e(),(fieldInfo.footer_content)?_c('p',{staticClass:"help-sidebar-footer-text"},[_vm._v(" "+_vm._s(fieldInfo.footer_content)+" ")]):_vm._e()]):_vm._e()]})],2)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }